import React from 'react';
import Image from 'next/image';
import { ArtistAvatarPlaceholder, ButtonText } from '@imus/base-ui';
import type { ReleasePageArtist } from '../types';
import { css } from '@emotion/react';
import { clickableStyle } from 'imdui/src/components/button/Clickable';
import styled from '@emotion/styled';

type Props = ReleasePageArtist;

export const avatarStyle = css`
  border-radius: 50%;
  z-index: 1;
  display: flex;
  width: var(--chip-avatar, 32px);
  height: var(--chip-avatar, 32px);
  justify-content: center;
  align-items: center;
`;

const ArtistLink = styled('a')`
  border-radius: var(--button-border-radius, 20px);
  border: 1px solid var(--outline-var, #474745);
  background: var(--surface-container-high, #3e3d3c);

  display: flex;
  height: var(--button-height, 40px);
  padding: 0px var(--chip-padding-right, 16px) 0px var(--chip-padding-left, 4px);
  justify-content: center;
  align-items: center;
  gap: var(--chip-gap, 16px);
  ${clickableStyle({ onClick: true })}
`;
const ArtistItem: React.FC<Props> = ({
  title: name,
  pageKey: pageId,
  avatar,
}) => {
  // i18next-extract-disable-next-line
  return (
    <ArtistLink
      target="_blank"
      rel="noreferrer"
      href={pageId ? '/artist/' + pageId : '#'}
    >
      {avatar?.url ? (
        <Image
          alt={name}
          css={avatarStyle}
          src={avatar?.url}
          width={140}
          height={140}
        />
      ) : (
        <ArtistAvatarPlaceholder css={avatarStyle} />
      )}

      <ButtonText>{name}</ButtonText>
    </ArtistLink>
  );
};

export default ArtistItem;
