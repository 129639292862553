import styled from '@emotion/styled';

//
//
// Cover section
//

/** */
export const CoverTitle = styled.h1`
  color: var(--Cover-Section-FG, #fff);
  text-align: center;
  font-size: var(--cover-title, 24px);
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
  letter-spacing: -0.24px;

  @container template-sizer (width > 512px) {
    font-size: 32px;
  }

  @container template-sizer (width > 1024px) {
    font-size: 40px;
  }
`;
/** */
export const CoverSubtitle = styled.span`
  color: var(--Cover-Section-FG, #fff);
  text-align: center;
  font-size: var(--cover-subtitle, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  opacity: 0.8;

  @container template-sizer (width > 512px) {
    font-size: 20px;
  }

  @container template-sizer (width > 1024px) {
    font-size: 24px;
  }
`;

//
//
// Content section
//

export * from '@imus/base-ui/src/text';
