/**
 * All accent colors
 */

const accent = {
  green: 'var(--accent)',
  orange: 'var(--tertiary-accent)',
  subscription: 'var(--tertiary-accent)',
};

/**
 * State colors
 */
const state = {
  warning: '#f9a825',
  error: 'var(--error-error)',
};

/**
 * Monochromatic palette of black shades for light background
 */

/**
 * Theme with light background (Default)
 */
export const theme = {
  foreground: {
    primary: 'rgba(0, 0, 0, 0.8)',
    secondary: '#555',
    disabled: '#666666',
  },
  accent,
  state,
  lights: {
    1: 'var(--surface-container-high)',
    2: 'var(--surface-container-medium)',
    3: 'var(--surface-container-low)',
    4: 'var(--background)',
  },
  darks: {
    1: 'var(--on-surface)',
    2: 'var(--on-surface-var)',
    3: 'var(--on-surface-var-2)',
    4: 'var(--outline-var)',
  },
  background: 'var(--background)',
  component: {
    radius: '8px',
    border: '#ececec',
    card: '#FFFFFF',
    button: {
      background: '#E5E5E6',
      focusBackground: '#0000004d',
    },
    input: {
      background: '#FAFAFA',
      border: '#DDDDDD',
      focusBorder: '#CCCCCC',
    },
  },
};

export const themeLight = theme;
export default theme;
