import styled from '@emotion/styled';
import { darkTheme, LIGHT_MODE_COLORS } from '@imus/base-ui';
import * as servicesColors from './theme';
type Props = {
  accent?: string;
  background?: string;
  mode: 'dark' | 'light' | 'auto';
};
export const ThemeSelector = styled.div<Props>`
  ${servicesColors.primitives}

  ${({ mode }) => {
    switch (mode) {
      case 'auto':
        return ``;
      case 'dark':
        return servicesColors.darkTheme;
      case 'light':
        return servicesColors.lightTheme;
    }
  }}

${({ mode }) => {
    switch (mode) {
      case 'dark':
        return darkTheme;
      case 'light':
        return LIGHT_MODE_COLORS;
      default:
        return '';
    }
  }}

  
@media (prefers-color-scheme: dark) {
    ${({ mode }) => (mode === 'light' ? '' : darkTheme)}
    ${({ mode }) =>
      mode === 'light' ? servicesColors.lightTheme : servicesColors.darkTheme}
  }
  @media (prefers-color-scheme: light) {
    ${({ mode }) => (mode === 'dark' ? '' : servicesColors.lightTheme)}
  }

  --accent: ${({ accent }) => accent};
  --background: ${({ background }) => background};
  background: ${({ background }) => background};
  & > * {
    --bg-1: var(--surface);
    --fg-4: var(--outline);
    --outline-var: var(--outline);
    --surface-container-high: var(--surface);
  }
`;
